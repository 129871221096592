import { TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IdentificationMenu } from 'components/menu/IdentificationMenu'
import { formTypeValues, getIdType, getMaxlength } from 'lib'
import { ICompanyInformation, IDENTIFICATION_TYPE } from 'marketplace-common'
import React from 'react'
import { IFormControl, IValidateErrors } from 'types'

type Props = {
  companyInformation: ICompanyInformation
  fieldErrors: IValidateErrors
  setFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  changeCompanyInformation: (companyInfo: ICompanyInformation) => void
  selectedIdType: string | undefined
  handleSelectedIdType: (val: string) => void
  formControl?: IFormControl
}

export const BillingForm = (props: Props) => {
  const {
    companyInformation,
    fieldErrors,
    setFieldErrors,
    changeCompanyInformation,
    handleSelectedIdType,
    selectedIdType,
    formControl,
  } = props

  const handleChangeField =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value
      const isRucOrId =
        selectedIdType === IDENTIFICATION_TYPE.CARD_ID || selectedIdType === IDENTIFICATION_TYPE.RUC

      const newCompanyInformation = {
        companyId: companyInformation.companyId,
        informationType: '2',
        enable: false,
        default: false,
        identification:
          fieldName === 'identification'
            ? isRucOrId
              ? value.replace(/[^0-9]/g, '')
              : value.replace(/[^a-zA-Z0-9]/g, '')
            : companyInformation.identification,
        name: fieldName === 'name' ? value : companyInformation.name,
        mail: fieldName === 'mail' ? value : companyInformation.mail,
        address: fieldName === 'address' ? value : companyInformation.address,
        identificationType: getIdType(selectedIdType ?? ''),
        phoneNumber:
          fieldName === 'phoneNumber'
            ? value.replace(/[^0-9]/g, '')
            : companyInformation.phoneNumber,
      } as ICompanyInformation

      changeCompanyInformation(newCompanyInformation)
      setFieldErrors((prevErrors) => ({
        identification: fieldName === 'identification' ? '' : prevErrors.identification,
        address: fieldName === 'address' ? '' : prevErrors.address,
        mail: fieldName === 'mail' ? '' : prevErrors.mail,
        name: fieldName === 'name' ? '' : prevErrors.name,
        phoneNumber: fieldName === 'phoneNumber' ? '' : prevErrors.phoneNumber,
      }))
    }
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Box display={'flex'} flexDirection={'row'} gap={1}>
          <Box display={'flex'} flexDirection={'column'} gap={1} sx={{ width: '100%' }}>
            <Box display={'flex'} flexDirection={'row'} gap={1} sx={{ width: '100%' }}>
              <IdentificationMenu
                handleSelectedIdType={handleSelectedIdType}
                selectedIdType={selectedIdType}
                tabValue={formControl?.tabValue}
                infoType={formControl?.infoType}
              />
              <Box display={'flex'} flexDirection={'column'} gap={1} sx={{ width: '100%' }}>
                <TextField
                  variant="filled"
                  label="Identificación"
                  fullWidth
                  inputProps={{
                    maxLength: getMaxlength(selectedIdType),
                  }}
                  autoFocus
                  required
                  value={companyInformation?.identification ?? ''}
                  onChange={handleChangeField('identification')}
                />
                {fieldErrors.identification && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.identification}
                  </Typography>
                )}
              </Box>
            </Box>
            <TextField
              variant="filled"
              label={'Cliente'}
              fullWidth
              required
              value={companyInformation?.name ?? ''}
              onChange={handleChangeField('name')}
            />
            {fieldErrors.name && (
              <Typography variant="caption" color="error">
                {fieldErrors.name}
              </Typography>
            )}
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={1} sx={{ width: '100%' }}>
            <TextField
              variant="filled"
              label="Correo electrónico"
              fullWidth
              required
              value={companyInformation?.mail ?? ''}
              onChange={handleChangeField('mail')}
            />
            {fieldErrors.mail && (
              <Typography variant="caption" color="error">
                {fieldErrors.mail}
              </Typography>
            )}

            <TextField
              variant="filled"
              label="Teléfono"
              fullWidth
              required
              inputProps={{ maxLength: 10 }}
              value={companyInformation?.phoneNumber ?? ''}
              onChange={handleChangeField('phoneNumber')}
            />
            {fieldErrors.phoneNumber && (
              <Typography variant="caption" color="error">
                {fieldErrors.phoneNumber}
              </Typography>
            )}
          </Box>
        </Box>
        <TextField
          variant="filled"
          label="Dirección"
          fullWidth
          value={companyInformation?.address ?? ''}
          onChange={handleChangeField('address')}
        />
        {fieldErrors.address && (
          <Typography variant="caption" color="error">
            {fieldErrors.address}
          </Typography>
        )}
      </Box>
    </>
  )
}
