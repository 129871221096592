import { IDENTIFICATION_TYPE } from 'marketplace-common'

export const RUC_ID = '04'
export const IDENTIFICATION_CARD_ID = '05'
export const FINAL_COSTUMER_ID = '07'
export const PASSPORT = '06'
export const OUTSIDE_ID = '08'

export const REQUIRE_VALIDATION_TYPES = [
  RUC_ID,
  IDENTIFICATION_CARD_ID,
  FINAL_COSTUMER_ID,
  PASSPORT,
  OUTSIDE_ID,
]

export const getIdType = (type: string) => {
  switch (type) {
    case RUC_ID:
      return IDENTIFICATION_TYPE.RUC
    case IDENTIFICATION_CARD_ID:
      return IDENTIFICATION_TYPE.CARD_ID
    case PASSPORT:
      return IDENTIFICATION_TYPE.PASSPORT
    case OUTSIDE_ID:
      return IDENTIFICATION_TYPE.OUTSIDE_ID
    default:
      return IDENTIFICATION_TYPE.FINAL_COSTUMER
  }
}
