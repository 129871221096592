import { Menu, MenuItem, Button, Typography, Icon } from '@mui/material'
import { useOpenMenu, useGetIdentificationTypes } from 'hooks'
import { FINAL_COSTUMER_ID, formTypeValues, IDENTIFICATION_CARD_ID, RUC_ID } from 'lib'
import React from 'react'

type Props = {
  selectedIdType: string | undefined
  handleSelectedIdType: (val: string) => void
  tabValue?: formTypeValues
  isPaymentForm?: boolean
  infoType?: formTypeValues
}
export const IdentificationMenu = (props: Props) => {
  const { handleSelectedIdType, selectedIdType, tabValue, isPaymentForm, infoType } = props
  const identificationRef = React.useRef<any>(null)
  const { anchorEl, handleClick, handleClose, open } = useOpenMenu()
  const { identificationTypes } = useGetIdentificationTypes()

  const isFormPage = tabValue !== undefined || infoType !== undefined

  const idTypeValidation = isFormPage
    ? tabValue !== formTypeValues.BILLING || infoType !== formTypeValues.BILLING_OR_PAYMENT
    : isPaymentForm

  const identificationTypeFilterList = idTypeValidation
    ? identificationTypes.filter(
        (i) => i.identificationTypeId == RUC_ID || i.identificationTypeId == IDENTIFICATION_CARD_ID
      )
    : identificationTypes.filter((i) => i.identificationTypeId !== FINAL_COSTUMER_ID)

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {identificationTypeFilterList.map((identificationType) => (
          <MenuItem
            key={identificationType.identificationTypeId}
            onClick={() => {
              setTimeout(() => {
                identificationRef.current?.focus()
              }, 200)
              handleClose()
              handleSelectedIdType(identificationType.identificationTypeId)
            }}
            dense
            selected
          >
            {identificationType.description}
          </MenuItem>
        ))}
      </Menu>
      <Button
        variant="contained"
        sx={{
          py: 1.25,
          pl: 2,
          justifyContent: 'space-between',
          display: 'flex',
          width: '45%',
          maxHeight: '48px',
        }}
        disableRipple
        onClick={handleClick}
        tabIndex={-1}
      >
        <Typography variant="caption" fontWeight={600} lineHeight={1}>
          {identificationTypes.find((i) => i.identificationTypeId === selectedIdType)
            ?.description ?? 'Tipo de identificación'}
        </Typography>
        <Icon sx={{ ml: 0.5 }}>keyboard_arrow_down</Icon>
      </Button>
    </>
  )
}
