import { LoadingButton } from '@mui/lab'
import { CircularProgress, Icon, Switch, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IdentificationMenu } from 'components/menu/IdentificationMenu'
import { formTypeValues, getMaxlength } from 'lib'
import { ICompanyInformation, IDENTIFICATION_TYPE } from 'marketplace-common'
import { IValidateErrors } from 'types'

type Props = {
  fieldErrors: IValidateErrors
  companyInformation: ICompanyInformation
  handleChangePayment: (key: keyof ICompanyInformation, value: string) => void
  updatingPayment: boolean
  existCompanyInfoList: boolean
  updatingDefault: boolean
  edit: boolean
  isPaymentTypeForm: boolean
  isCompanySelected: boolean
  handleSetDefault: (companyInformationId: number) => Promise<void>
  selectedIdType: string | undefined
  handleSelectedIdType: (val: string) => void
}

export const AccountCompanyInfoForm = (prop: Props) => {
  const {
    fieldErrors,
    companyInformation,
    handleChangePayment,
    updatingPayment,
    existCompanyInfoList,
    updatingDefault,
    handleSetDefault,
    edit,
    isPaymentTypeForm,
    isCompanySelected,
    handleSelectedIdType,
    selectedIdType,
  } = prop

  const isPaymentCompanyInfo =
    companyInformation.informationType === formTypeValues.PAYMENT && isCompanySelected

  const renderPaymentFields = isPaymentTypeForm || isPaymentCompanyInfo

  return (
    <>
      <Box display={'flex'} gap={2} flexDirection={{ xs: 'column', sm: 'row' }} py={3} px={4}>
        <Box
          display={'flex'}
          gap={1}
          flexDirection={'column'}
          sx={{ width: { xs: '100%', sm: '50%' } }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box>
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {renderPaymentFields ? 'Primer Nombre *' : 'Cliente *'}
              </Typography>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  value={companyInformation.name}
                  onChange={(e) => handleChangePayment('name', e.target.value)}
                  disabled={updatingPayment}
                  fullWidth
                />
                {fieldErrors.name && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ position: 'absolute', bottom: -20, left: 0 }}
                  >
                    {fieldErrors.name}
                  </Typography>
                )}
              </Box>
            </Box>
            {renderPaymentFields && (
              <Box>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Apellido *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    value={companyInformation.lastName}
                    onChange={(e) => handleChangePayment('lastName', e.target.value)}
                    disabled={updatingPayment}
                    fullWidth
                  />
                  {fieldErrors.lastName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.lastName}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box>
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {'Identificación *'}
              </Typography>
              <Box
                sx={{ position: 'relative', width: '100%' }}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
              >
                <IdentificationMenu
                  handleSelectedIdType={handleSelectedIdType}
                  selectedIdType={selectedIdType}
                  isPaymentForm={isPaymentTypeForm}
                />
                <TextField
                  value={companyInformation.identification}
                  onChange={(e) => {
                    const value = e.target.value
                    const isRucOrId =
                      selectedIdType === IDENTIFICATION_TYPE.CARD_ID ||
                      selectedIdType === IDENTIFICATION_TYPE.RUC
                    handleChangePayment(
                      'identification',
                      isRucOrId ? value.replace(/[^0-9]/g, '') : value.replace(/[^a-zA-Z0-9]/g, '')
                    )
                  }}
                  disabled={updatingPayment}
                  fullWidth
                  inputProps={{
                    maxLength: getMaxlength(selectedIdType),
                  }}
                />
                {fieldErrors.identification && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ position: 'absolute', bottom: -20, left: 0 }}
                  >
                    {fieldErrors.identification}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {'Dirección'}
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  value={companyInformation.address}
                  onChange={(e) => handleChangePayment('address', e.target.value)}
                  disabled={updatingPayment}
                  fullWidth
                />
                {fieldErrors.address && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ position: 'absolute', bottom: -20, left: 0 }}
                  >
                    {fieldErrors.address}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          gap={1}
          flexDirection={'column'}
          sx={{ width: { xs: '100%', sm: '50%' } }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {renderPaymentFields && (
              <Box>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Segundo Nombre'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    value={companyInformation.middleName}
                    onChange={(e) => handleChangePayment('middleName', e.target.value)}
                    disabled={updatingPayment}
                    fullWidth
                  />
                  {fieldErrors.middleName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.middleName}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}

            <Box>
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {'Correo *'}
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  type="email"
                  value={companyInformation.mail}
                  onChange={(e) => handleChangePayment('mail', e.target.value)}
                  disabled={updatingPayment}
                  fullWidth
                />
                {fieldErrors.mail && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ position: 'absolute', bottom: -20, left: 0 }}
                  >
                    {fieldErrors.mail}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box>
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {'Número de celular *'}
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  value={companyInformation.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value
                    handleChangePayment('phoneNumber', value.replace(/[^0-9]/g, ''))
                  }}
                  disabled={updatingPayment}
                  inputProps={{ maxLength: 10 }}
                  fullWidth
                />
                {fieldErrors.phoneNumber && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ position: 'absolute', bottom: -20, left: 0 }}
                  >
                    {fieldErrors.phoneNumber}
                  </Typography>
                )}
              </Box>
            </Box>
            {existCompanyInfoList && edit && (
              <Box
                sx={{
                  width: { xs: '100%', md: 'calc(50% - 8px)' },
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {!companyInformation.default &&
                  companyInformation.informationType !== formTypeValues.BILLING && (
                    <>
                      <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                        {'Establecer Por Defecto'}
                      </Typography>
                      {updatingDefault && <CircularProgress size={20} />}
                      {!updatingDefault && (
                        <Switch
                          checked={companyInformation.default}
                          onChange={() => handleSetDefault(companyInformation.companyInformationId)}
                          disabled={updatingDefault}
                        />
                      )}
                    </>
                  )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: { xs: 1, sm: 4 },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'center', sm: 'flex-end' },
        }}
      >
        <LoadingButton
          sx={{
            px: { xs: 3, sm: 2 },
            py: { xs: 1.5 },
            gap: { xs: 1 },
            fontSize: { xs: 15 },
            fontWeight: 600,
            lineHeight: 1,
            width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(33% - 8px)' },
          }}
          type="submit"
          loading={updatingPayment}
          onClick={() => {
            handleChangePayment('informationType', isPaymentTypeForm ? '1' : '2')
          }}
        >
          {edit
            ? renderPaymentFields
              ? 'Editar Información de Pago'
              : 'Editar Información de Facturación'
            : renderPaymentFields
            ? 'Crear Información de Pago'
            : 'Crear Información de Facturación'}
          <Icon>credit_card</Icon>
        </LoadingButton>
      </Box>
    </>
  )
}
