import {
  formTypeValues,
  IDENTIFICATION_CARD_LENGTH,
  IDENTIFICATION_TYPES_PATH,
  validateIdentificationCard,
  validateOutSideId,
  validatePassport,
  validateSingleRegistryOfTaxpayers,
} from 'lib'
import { ICompanyInformation, IDENTIFICATION_TYPE } from 'marketplace-common'
import React from 'react'
import { IValidateErrors } from 'types'
import { fieldValidationDirection, fieldValidationEmail, fieldValidationPhone } from 'utils'

type Hook = {
  noAllFields: (field: any) => boolean
  fieldErrors: IValidateErrors
  invoiceFieldErrors: IValidateErrors
  setFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  setInvoiceFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  validateCompanyInformation: (comp?: ICompanyInformation, idType?: string) => boolean
  setCompanyInformation: React.Dispatch<React.SetStateAction<ICompanyInformation>>
}

const defaultErrorsFields = {
  name: '',
  middleName: '',
  lastName: '',
  mail: '',
  identification: '',
  phoneNumber: '',
  address: '',
} as IValidateErrors

export const useValidateCompanyInformation = (): Hook => {
  const [companyInformation, setCompanyInformation] = React.useState({} as ICompanyInformation)
  const [fieldErrors, setFieldErrors] = React.useState<IValidateErrors>({} as IValidateErrors)
  const [invoiceFieldErrors, setInvoiceFieldErrors] =
    React.useState<IValidateErrors>(defaultErrorsFields)

  const validateCompanyInformation = (comp?: ICompanyInformation, idType?: string) => {
    const errors: Partial<IValidateErrors> = {}
    const companyInfoToValidate = comp ?? companyInformation
    const trimmedIdentification = companyInfoToValidate.identification?.trim() || ''

    const isEmpty = (value?: string) => !value?.trim()
    const isInvalidPhone = (phone?: string) => phone && !fieldValidationPhone(phone.trim())
    const isInvalidEmail = (email?: string) => email && !fieldValidationEmail(email.trim())

    // Validación de nombre
    if (isEmpty(companyInfoToValidate?.name)) {
      errors.name = 'El campo Primer Nombre es requerido'
    }

    // Validación de correo electrónico
    if (isEmpty(companyInfoToValidate?.mail)) {
      errors.mail = 'El campo Correo Electrónico es requerido'
    } else if (isInvalidEmail(companyInfoToValidate?.mail)) {
      errors.mail = 'El correo ingresado no es válido'
    }

    // Validación de identificación
    if (isEmpty(trimmedIdentification)) {
      errors.identification = 'El campo Identificación es requerido'
    } else if (
      idType === IDENTIFICATION_TYPE.CARD_ID &&
      trimmedIdentification.length < IDENTIFICATION_CARD_LENGTH
    ) {
      errors.identification = 'Su identificación debe ser igual a 10 dígitos'
    } else if (
      idType === IDENTIFICATION_TYPE.CARD_ID &&
      !validateIdentificationCard(trimmedIdentification)
    ) {
      errors.identification = 'Su identificación no es válida'
    } else if (
      idType === IDENTIFICATION_TYPE.RUC &&
      !validateSingleRegistryOfTaxpayers(trimmedIdentification)
    ) {
      errors.identification = 'RUC inválido'
    } else if (
      idType === IDENTIFICATION_TYPE.OUTSIDE_ID &&
      !validateOutSideId(trimmedIdentification)
    ) {
      errors.identification = 'Ingrese una identificación válida'
    } else if (
      idType === IDENTIFICATION_TYPE.PASSPORT &&
      !validatePassport(trimmedIdentification)
    ) {
      errors.identification = 'Ingrese una identificación válida'
    }

    // Validación de teléfono
    if (isEmpty(companyInfoToValidate?.phoneNumber)) {
      errors.phoneNumber = 'El campo Teléfono es requerido'
    } else if (isInvalidPhone(companyInfoToValidate?.phoneNumber)) {
      errors.phoneNumber = 'No tiene el formato correcto'
    }

    // Validación de dirección
    if (
      companyInfoToValidate?.address?.trim() &&
      fieldValidationDirection(companyInfoToValidate?.address?.trim())
    ) {
      errors.address = 'La dirección no debe contener [{}[]!¡¿?%$=*+]'
    }

    // Aplicar los errores según el tipo de formulario
    const setErrors =
      comp?.informationType === formTypeValues.BILLING ? setInvoiceFieldErrors : setFieldErrors
    setErrors(errors as IValidateErrors)

    return Object.keys(errors).length === 0
  }

  const noAllFields = (field: any) => {
    const requiredProperties = ['identification', 'name', 'lastName', 'mail', 'phoneNumber']
    for (const key of requiredProperties) {
      if (!field[key]) {
        return false
      }
    }
    return true
  }

  return {
    fieldErrors,
    invoiceFieldErrors,
    setFieldErrors,
    setInvoiceFieldErrors,
    noAllFields,
    setCompanyInformation,
    validateCompanyInformation,
  }
}
