import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  Icon,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Typography,
  useTheme,
} from '@mui/material'

import {
  BillingForm,
  CleanItemsBtn,
  DialogValidateUserId,
  PaymentForm,
  ProcessToolbar,
  ProductsDetails,
} from 'components'

import format from 'date-fns/format'
import {
  useAuth,
  useCart,
  useCompanyInformation,
  useFeedback,
  useInvoiceContract,
  useLocalStorage,
  useProcess,
  useTransaction,
  useValidateCompanyInformation,
} from 'hooks'
import { useProcessSelection } from 'hooks/processSelection/useProcessSelection'
import {
  changeCompanyIdToRucService,
  constLocalStorage,
  formTypeValues,
  getCompanyInformationByIdService,
  getIdTypeByLength,
  IDENTIFICATION_CARD_ID,
} from 'lib'
import { ICompanyInformation, IPromocionalCode } from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { IFormControl, StepReferenceName } from 'types'

import { motion } from 'framer-motion'
import { ModelOtherAccount } from '../account/ModelOtherAccount'

type LoadOptions =
  | 'noSavingLoading'
  | 'savingLoading'
  | 'loading'
  | 'loadingContinue'
  | 'loadingConfirmProductsNeedsRuc'
  | 'loadingConfirmProductsNotAvailable'

export const InvoiceForm = () => {
  const { user, isAuthenticated, showSelectProcess } = useAuth()
  const { getItem, storeItem, deleteItem } = useLocalStorage()
  const { getTransactionsByProcessId, handleSaveTransaction } = useTransaction()
  const { palette } = useTheme()
  const { showMessage } = useFeedback()
  const { handleOpenDialog, handleCloseDialog, openDialog, handleCloseAllDialog } =
    useProcessSelection()
  const { handleSubmitInvoiceContract } = useInvoiceContract()

  const {
    process: processInfo,
    switchNextStep,
    handleAssignedCompanyInformationIdToProcess,
    addEnableSteps,
    handleValidateCodeLogged,
    handleValidateCodeNotLogged,
    handleRemoveCode,
    loadingValidate,
    code,
    handleChangeCode,
    shopToAnotherAccount,
    handleAssignedCompanyToProcess,
    loadingInitialize,
    initializeConfigureAfterLogin,
    changeStepIndex,
    currentStep,
  } = useProcess()

  const isNewProcess =
    processInfo?.companyInformationId === null && processInfo?.invoiceInformationId === null
  const processWithBillingInfo =
    processInfo?.companyInformationId !== processInfo?.invoiceInformationId && !isNewProcess
  const {
    nonEmptyCart,
    productsIds,
    specialProductsId,
    specialProductsIdFromLocalStorage,
    productsIdsFromLocalStorage,
    specialProductExpired,
    loadingShoppingCart,
    handleChangeApplyCodeFlag,
    validateProductsByUserId,
    invoiceDetails,
    handleInvoiceDetails,
    loadingInvoice,
  } = useCart()
  const {
    fieldErrors,
    setFieldErrors,
    validateCompanyInformation,
    invoiceFieldErrors,
    setInvoiceFieldErrors,
  } = useValidateCompanyInformation()
  const {
    companyInformation,
    invoiceInformation,
    changeCompanyInformation,
    changeInvoiceInformation,
    alreadyExistsCompanyInformation,
    changeAlreadyExistsCompanyInformation,
    handleSaveCompanyInformationDefault,
    handleSaveCompanyInformationDisabled,
    handleSaveInvoiceInformation,
    handleSaveCompanyInformationEnabled,
    companyInformationList,
    handleCompanyInformationList,
  } = useCompanyInformation()

  /* STATES */
  const [load, setLoad] = React.useState<LoadOptions[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [formControl, setFormControl] = React.useState<IFormControl>({
    tabValue: formTypeValues.BILLING,
    needBillingInfo: processWithBillingInfo,
    infoType: processWithBillingInfo
      ? formTypeValues.BILLING_OR_PAYMENT
      : formTypeValues.BILLING_AND_PAYMENT,
  })
  const [selectedCompanyInfoIdType, setSelectedCompanyInfoIdType] =
    React.useState(IDENTIFICATION_CARD_ID)
  const [selectedInvoiceInfoIdType, setSelectedInvoiceInfoIdType] =
    React.useState(IDENTIFICATION_CARD_ID)

  /* CONST */
  const isSelectProcessOpen = openDialog.includes('selectProcess') && showSelectProcess
  const loading = load.includes('loading') || loadingInvoice
  const loadingContinue = load.includes('loadingContinue')
  const noSavingLoading = load.includes('noSavingLoading')
  const savingLoading = load.includes('savingLoading')

  const existsProcessId = Boolean(processInfo?.processId)
  const existsCompanyId = Boolean(user?.identification)
  const openCompanyInformationMenu = Boolean(anchorEl)

  const existsCompanyInformationList = Boolean(
    companyInformationList?.length &&
      companyInformationList.some((company) => company.enable && company.default)
  )

  const amount = process.env.REACT_APP_MINIMUM_AMOUNT
  const minimumAmount = amount !== undefined ? parseFloat(amount) : 0
  const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
  const codeLocal = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode

  /* MEMOS */

  const companyInformationEnable = React.useMemo(() => {
    return companyInformationList?.length > 0
      ? companyInformationList!.filter((comp) => {
          if (formControl.needBillingInfo) {
            return comp.enable && comp.informationType === formControl.tabValue
          } else {
            return comp.enable && comp.informationType === formTypeValues.PAYMENT
          }
        })
      : []
  }, [companyInformationList, formControl])

  const loadingAdd = React.useMemo(
    () =>
      loadingShoppingCart.specialProductId.length > 0 || loadingShoppingCart.productId.length > 0,
    [loadingShoppingCart]
  )
  const isLoading = React.useMemo(() => {
    return loading || loadingAdd || loadingValidate
  }, [loading, loadingAdd, loadingValidate])

  const promotionalCodeLocal = React.useMemo(() => {
    if (!isAuthenticated && promotionalCode !== undefined) {
      return promotionalCode
    } else {
      return undefined
    }
  }, [isAuthenticated, promotionalCode])

  const promotionalCodeActive = React.useMemo(() => {
    if (isAuthenticated) {
      return processInfo !== undefined && processInfo.promotionalCode !== null
    } else {
      if (promotionalCodeLocal !== undefined) {
        return true
      } else {
        return false
      }
    }
  }, [isAuthenticated, processInfo, promotionalCodeLocal])

  const isCode: boolean = React.useMemo(() => {
    return code !== undefined && code.length > 0
  }, [code])
  const getCode = React.useMemo(() => {
    if (isAuthenticated && processInfo?.promotionalCode?.code) {
      const processPromotionalCode = processInfo?.promotionalCode?.code
      return processPromotionalCode
    } else {
      if (codeLocal !== undefined) {
        return codeLocal.code
      }
    }
  }, [codeLocal, isAuthenticated, processInfo?.promotionalCode?.code])

  const codeValid = React.useMemo(() => {
    return processInfo?.promotionalCode !== null ? true : false
  }, [processInfo])

  /* FUNCIONES */
  const handleCompanyInfoIdType = (val: string) => setSelectedCompanyInfoIdType(val)
  const handleInvoiceInfoIdType = (val: string) => setSelectedInvoiceInfoIdType(val)

  const handleChangeFormControl = (newState: Partial<IFormControl>) => {
    setFormControl((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: formTypeValues) => {
    handleChangeFormControl({ tabValue: newValue })
  }
  const handleLoad = (dialogId: LoadOptions) => setLoad((prev) => [...prev, dialogId])
  const handleCloseLoad = (load: LoadOptions) =>
    setLoad((prev) => prev.filter((option) => option !== load))

  const handleOpenCompanyInformationMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseCompanyInformationMenu = () => setAnchorEl(null)

  const handleComplete = (e: React.FormEvent) => {
    e.preventDefault()
  }

  const handleSaveInvoiceContractAndTransaction = async () => {
    const existsProcessId = Boolean(processInfo?.processId)
    if (!existsProcessId) {
      return
    }
    try {
      const transactionArray = await getTransactionsByProcessId(processInfo?.processId!)
      const currentTransaction = transactionArray[0]
      const invoiceContract = await handleSubmitInvoiceContract(
        processInfo?.processId!,
        currentTransaction?.invoiceContractId ?? null,
        codeValid
      )
      await handleSaveTransaction(
        invoiceContract.invoiceContractId,
        processInfo!,
        currentTransaction
      )
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleValidateSpecialProductsExpired = () => {
    if (specialProductExpired !== null) {
      showMessage(
        `Tienes un producto en tu carrito que ya no se encuentra disponible: ${specialProductExpired.name}`,
        'error',
        {
          horizontal: 'right',
          vertical: 'top',
        }
      )
    } else {
      return
    }
  }

  const handleBtnContinueLogged = async () => {
    handleLoad('loadingContinue')
    handleLoad('loading')

    const existsProductsNotAvailable = await validateProductsByUserId(
      processInfo?.processId!,
      user?.identificationType
    )
    if (existsProductsNotAvailable) {
      handleOpenDialog('productsNotAvailable')
      handleCloseLoad('loading')
      return
    }

    handleValidateSpecialProductsExpired()

    const fieldsAreOk = formControl.needBillingInfo
      ? validateCompanyInformation(invoiceInformation, selectedInvoiceInfoIdType) &&
        validateCompanyInformation(companyInformation, selectedCompanyInfoIdType)
      : validateCompanyInformation(companyInformation, selectedCompanyInfoIdType)

    const invoiceFieldsOk = validateCompanyInformation(
      invoiceInformation,
      selectedInvoiceInfoIdType
    )
    const companyFieldsOk = validateCompanyInformation(
      companyInformation,
      selectedCompanyInfoIdType
    )

    if (formControl.needBillingInfo) {
      const tabValue = !companyFieldsOk
        ? formTypeValues.PAYMENT
        : !invoiceFieldsOk
        ? formTypeValues.BILLING
        : formControl.tabValue
      handleChangeFormControl({ tabValue })
    }

    const totalInvoice = parseFloat(invoiceDetails.total.substring(1))
    const existsProducts =
      invoiceDetails.products.length !== 0 || invoiceDetails.specialProducts.length !== 0
    if (!existsProducts) {
      showMessage('Debes tener al menos un producto en tu carrito', 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else if (specialProductExpired === null) {
      if (totalInvoice < minimumAmount) {
        showMessage(`La compra mínima es de $ ${minimumAmount}`, 'error', {
          horizontal: 'right',
          vertical: 'top',
        })
      } else if (!fieldsAreOk) {
        showMessage(`Completa todos los campos del formulario`, 'error', {
          horizontal: 'right',
          vertical: 'top',
        })
      } else {
        try {
          const existCompanyInfo = formControl.needBillingInfo
            ? Boolean(invoiceInformation.companyInformationId) &&
              Boolean(companyInformation.companyInformationId)
            : Boolean(companyInformation.companyInformationId)

          if (!existCompanyInfo) {
            handleOpenDialog('saveInformation')
            handleCloseLoad('loadingContinue')
            handleCloseLoad('loading')
            return
          }

          const process = await handleAssignedCompanyInformationIdToProcess(
            companyInformation.companyInformationId,
            undefined,
            formControl.needBillingInfo
              ? invoiceInformation.companyInformationId
              : companyInformation.companyInformationId
          )
          await handleSaveInvoiceContractAndTransaction()
          if (!shopToAnotherAccount) {
            await handleAssignedCompanyToProcess(user.identification, process)
          }
          addEnableSteps(StepReferenceName.INVOICE)
          nonEmptyCart && switchNextStep(shopToAnotherAccount)
        } catch (error) {
          console.log('Error', error)
        }
      }
    }
    handleCloseLoad('loadingContinue')
    handleCloseLoad('loading')
  }

  const handleChangeIdentificationToRuc = async (
    identification: string,
    newRuc: string,
    companyName: string
  ) => {
    handleLoad('loadingConfirmProductsNotAvailable')
    try {
      await changeCompanyIdToRucService(identification, newRuc, companyName)
      window.location.reload()
    } catch (error) {
      handleCloseDialog('confirmProductsNotAvailable')
      handleCloseDialog('productsNotAvailable')
      handleCloseLoad('loadingConfirmProductsNotAvailable')
      handleCloseLoad('loadingContinue')
      showMessage(
        'Se produjo un error al intentar cambiar la identificación a RUC, por favor comuníquese con soporte para ayuda.',
        'error'
      )
      console.error(error)
    }
  }

  const handleBtnContinueNoLogged = () => {
    handleValidateSpecialProductsExpired()
    const fieldsAreOk = validateCompanyInformation(companyInformation, selectedCompanyInfoIdType)

    const totalInvoice = parseFloat(invoiceDetails.total.substring(1))
    const existsProducts =
      invoiceDetails.products.length !== 0 || invoiceDetails.specialProducts.length !== 0
    if (!existsProducts) {
      showMessage('Debes tener al menos un producto en tu carrito', 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else if (totalInvoice < minimumAmount) {
      showMessage(`La compra mínima es de $ ${minimumAmount}`, 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else if (!fieldsAreOk) {
      showMessage(`Completa todos los campos del formulario`, 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else {
      addEnableSteps(StepReferenceName.INVOICE)
      nonEmptyCart && handleOpenDialog('login')
    }
  }

  const handleSaveProcess = async (
    savingType: LoadOptions,
    saveCompanyFunc: () => Promise<number>
  ) => {
    try {
      handleLoad(savingType)

      const shouldCreateNewCompanyInfo = !Boolean(companyInformation.companyInformationId)
      const shouldCreateNewInvoiceInfo = !Boolean(invoiceInformation.companyInformationId)

      const companyInfoId = shouldCreateNewCompanyInfo
        ? await saveCompanyFunc()
        : companyInformation.companyInformationId

      const invoiceInfoId = formControl.needBillingInfo
        ? shouldCreateNewInvoiceInfo
          ? await handleSaveInvoiceInformation(savingType === 'savingLoading')
          : invoiceInformation.companyInformationId
        : undefined

      await handleAssignedCompanyInformationIdToProcess(companyInfoId, undefined, invoiceInfoId)
      await handleSaveInvoiceContractAndTransaction()
      addEnableSteps(StepReferenceName.INVOICE)
      switchNextStep(shopToAnotherAccount)
    } catch (error) {
      console.error('Error', error)
    } finally {
      if (savingType === 'noSavingLoading') {
        handleCloseLoad('noSavingLoading')
      }
      handleCloseAllDialog()
    }
  }

  const handleBtnSaveAndContinue = async () => {
    const saveCompanyFunc =
      formControl.needBillingInfo || alreadyExistsCompanyInformation
        ? handleSaveCompanyInformationEnabled
        : handleSaveCompanyInformationDefault

    await handleSaveProcess('savingLoading', saveCompanyFunc)
  }

  const handleBtnNotSave = async () => {
    await handleSaveProcess('noSavingLoading', handleSaveCompanyInformationDisabled)
  }

  const loadCompanyInformation = async () => {
    const { companyInformationId, invoiceInformationId } = processInfo || {}

    if (!companyInformationId) return
    if (!invoiceInformationId) return
    try {
      const [companyResult, invoiceResult] = await Promise.all([
        getCompanyInformationByIdService(companyInformationId),
        getCompanyInformationByIdService(invoiceInformationId),
      ])
      const { identificationType: companyInfoIdType, identification: companyIdentification } =
        companyResult as ICompanyInformation
      const { identificationType: invoiceInfoIdType } = invoiceResult as ICompanyInformation

      const companyIdType =
        companyInfoIdType === null
          ? getIdTypeByLength(companyIdentification.length)
          : companyInfoIdType
      const invoiceIdType =
        invoiceInfoIdType === null
          ? getIdTypeByLength(invoiceResult.identification.length)
          : invoiceInfoIdType

      setSelectedCompanyInfoIdType(companyIdType)
      setSelectedInvoiceInfoIdType(invoiceIdType)

      changeCompanyInformation(companyResult)
      changeInvoiceInformation(invoiceResult)
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  const existPromotionalCodeAndProcess: boolean = React.useMemo(() => {
    return code !== undefined && code.length > 0 && processInfo !== undefined
  }, [code, processInfo])

  const handleValidateApplyCode = async () => {
    if (isAuthenticated) {
      const response = await handleValidateCodeLogged(existPromotionalCodeAndProcess, code)
      if (response?.valid) {
        handleInvoiceDetails(
          productsIds,
          specialProductsId,
          response.promotionalCode.promotionalCodeId
        )
        const monetaryDiscount = response.promotionalCode?.monetaryDiscount
        ReactPixel.trackCustom('addPromotionalCode', {
          promotionalCode: code,
          discount: Boolean(monetaryDiscount)
            ? '$' + monetaryDiscount
            : response.promotionalCode?.percentage + '%',
        })
      }
    } else {
      const response = await handleValidateCodeNotLogged(isCode, code)
      if (response?.valid) {
        savePromotionalCode(response.promotionalCode)
        const productsId = existsProcessId ? productsIds : productsIdsFromLocalStorage
        const specialProductIds = existsProcessId
          ? specialProductsId
          : specialProductsIdFromLocalStorage

        handleInvoiceDetails(
          productsId,
          specialProductIds,
          response.promotionalCode.promotionalCodeId
        )
        handleChangeApplyCodeFlag(true)

        const monetaryDiscount = response.promotionalCode?.monetaryDiscount
        ReactPixel.trackCustom('addPromotionalCode', {
          promotionalCode: code,
          discount: Boolean(monetaryDiscount)
            ? '$' + monetaryDiscount
            : response.promotionalCode?.percentage + '%',
        })
      }
    }
  }

  const handleRemoveApplyCode = async () => {
    if (isAuthenticated) {
      await handleRemoveCode()
      handleChangeCode('')
      handleInvoiceDetails(productsIds, specialProductsId)
    } else {
      deleteItem(constLocalStorage.PROMOTIONAL_CODE)
      const productsId = existsProcessId ? productsIds : productsIdsFromLocalStorage
      const specialProductIds = existsProcessId
        ? specialProductsId
        : specialProductsIdFromLocalStorage
      handleInvoiceDetails(productsId, specialProductIds)
      handleChangeApplyCodeFlag(false)
      handleChangeCode('')
    }
  }

  const savePromotionalCode = (promotionalCode: IPromocionalCode) => {
    storeItem(constLocalStorage.PROMOTIONAL_CODE, promotionalCode)
  }
  const getCompanyInformation = (informationType: string, isDefault = false) =>
    companyInformationList.find(
      (company) =>
        company.enable &&
        company.informationType === informationType &&
        (!isDefault || company.default)
    )
  const handleChangeFormType = () => {
    const invoiceInformation = getCompanyInformation(formTypeValues.BILLING)
    const companyInformation = getCompanyInformation(formTypeValues.PAYMENT, true)

    if (invoiceInformation) {
      changeInvoiceInformation(invoiceInformation)
      setSelectedInvoiceInfoIdType(
        invoiceInformation.identificationType === null
          ? getIdTypeByLength(invoiceInformation.identification.length)
          : invoiceInformation.identificationType
      )
    }
    if (companyInformation) {
      changeCompanyInformation(companyInformation)
      setSelectedCompanyInfoIdType(
        companyInformation.identificationType === null
          ? getIdTypeByLength(companyInformation.identification.length)
          : companyInformation.identificationType
      )
    }

    const infoTypeToSet =
      formControl.infoType === formTypeValues.BILLING_AND_PAYMENT
        ? {
            infoType: formTypeValues.BILLING_OR_PAYMENT,
            needBillingInfo: true,
          }
        : {
            infoType: formTypeValues.BILLING_AND_PAYMENT,
            needBillingInfo: false,
          }

    handleChangeFormControl(infoTypeToSet)
  }
  /* EFFECTS */

  React.useEffect(() => {
    changeAlreadyExistsCompanyInformation(existsCompanyInformationList)
  }, [existsCompanyInformationList])

  React.useEffect(() => {
    const existsInvoiceDetails =
      Boolean(invoiceDetails.products) || Boolean(invoiceDetails.specialProducts)
    if (!existsInvoiceDetails) {
      handleLoad('loading')
      return
    }
    handleCloseLoad('loading')
  }, [invoiceDetails])

  React.useEffect(() => {
    if (!isAuthenticated) {
      const companyInformationLocal = getItem(constLocalStorage.COMPANY_INFO)
      if (Boolean(companyInformationLocal)) {
        changeCompanyInformation(companyInformationLocal)
      }
    }
  }, [isAuthenticated, getItem])

  React.useEffect(() => {
    if (existsCompanyId) {
      handleCompanyInformationList(user.identification)
    }
    if (existsProcessId) {
      loadCompanyInformation()
    }
  }, [])

  React.useEffect(() => {
    if (isSelectProcessOpen) return

    if (!existsCompanyInformationList) return

    const defaultEnabledCompany = companyInformationList.find(
      (company) => company.enable && company.default
    )

    if (!defaultEnabledCompany) return
    const defaultCompanyIdType =
      defaultEnabledCompany.identificationType === null || !defaultEnabledCompany.identificationType
        ? getIdTypeByLength(defaultEnabledCompany.identification.length)
        : defaultEnabledCompany.identificationType
    if (!processInfo?.companyInformation) {
      changeCompanyInformation(defaultEnabledCompany)
      setSelectedCompanyInfoIdType(defaultCompanyIdType)
    }

    if (!processInfo?.invoiceInformationId) {
      const invoiceInformation = companyInformationList.find(
        (company) => company.enable && company.informationType === formTypeValues.BILLING
      )
      if (!invoiceInformation) return
      const invoiceIdType =
        invoiceInformation.identificationType === null || !invoiceInformation.identificationType
          ? getIdTypeByLength(invoiceInformation.identification.length)
          : invoiceInformation.identificationType
      if (invoiceInformation) {
        changeInvoiceInformation(invoiceInformation)
        setSelectedInvoiceInfoIdType(invoiceIdType)
      }
    }
  }, [existsCompanyInformationList, companyInformationList, processInfo])

  return (
    <React.Fragment>
      <ProcessToolbar
        handleBtnContinue={isAuthenticated ? handleBtnContinueLogged : handleBtnContinueNoLogged}
        hideButton={false}
        loading={isLoading || loadingContinue}
      />
      <Box
        pb={{ xs: 12, md: 0 }}
        component="form"
        onSubmit={handleComplete}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Paper
          sx={{
            width: { xs: '56vw', sm: '55vw', md: '62vw', lg: '75%', xl: '86v%' },
            p: 3,
            m: 2,
            pb: 6,
          }}
        >
          <Stack spacing={1.5}>
            <Grid container direction={{ xs: 'column', md: 'row' }} alignItems={'center'} gap={2}>
              <Grid item flexGrow={1}>
                <Typography fontWeight={700} color="textSecondary" variant="h6">
                  {'Carrito de compras'}
                </Typography>
                <Grid container alignItems={'center'} pt={0.5}>
                  <Icon sx={{ mr: 1 }} color="action">
                    event
                  </Icon>
                  <Typography variant="body2">
                    {`Fecha: ${format(new Date(), 'dd-MM-yyyy')}`}
                  </Typography>
                </Grid>
              </Grid>

              <Icon
                color="action"
                fontSize="large"
                sx={{ pt: { xs: 2, md: 0 }, display: { xs: 'none', md: 'block' } }}
              >
                receipt_long
              </Icon>

              <Grid item container flexGrow={1} alignItems="center">
                <InputBase
                  placeholder="Código de descuento"
                  sx={{
                    fontSize: '0.8em',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    mr: 1,
                    flexGrow: { xs: 1, md: 4 },
                    mb: { xs: 1.4, sm: 0 },
                    pl: '8px',
                  }}
                  value={isCode ? code : getCode ?? ''}
                  onChange={(e) => {
                    handleChangeCode(e.target.value)
                  }}
                />
                <LoadingButton
                  endIcon={<Icon>{promotionalCodeActive ? 'delete' : 'add'}</Icon>}
                  disabled={loadingValidate}
                  loading={loadingValidate}
                  sx={{
                    px: 4,
                    flexGrow: 1,
                    background: promotionalCodeActive
                      ? palette.secondary.main
                      : palette.primary.main,
                    color: '#fff',
                  }}
                  onClick={async () => {
                    if (promotionalCodeActive) {
                      await handleRemoveApplyCode()
                    } else {
                      await handleValidateApplyCode()
                    }
                  }}
                >
                  {`${promotionalCodeActive ? 'Quitar Código' : 'Aplicar Código'}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>

          <Grid item sx={{ pt: 2 }}>
            <Typography fontWeight={700} color="textSecondary" variant="body2">
              {'Detalles'}
            </Typography>

            {specialProductExpired && (
              <Alert severity="warning">
                Una de las promociones ya no esta disponible en el marketplace, pero puedes
                comunicarte con soporte para tener mas información o elimina la promoción para
                continuar con la compra!
              </Alert>
            )}
          </Grid>

          <ProductsDetails
            invoiceDetail={invoiceDetails}
            disableDelete
            enableAddAndRemoveButtons
            loadingDetails={loading}
          />
          <Stack alignItems={{ xs: 'center', sm: 'flex-end' }} sx={{ pb: 3 }}>
            {nonEmptyCart ? (
              <CleanItemsBtn isAuth={isAuthenticated} processId={processInfo?.processId} />
            ) : (
              <Button
                endIcon={<Icon>storefront</Icon>}
                size="medium"
                onClick={async () => {
                  if (currentStep !== undefined) {
                    changeStepIndex(currentStep.index - 1)
                  }
                }}
                component={motion.div}
                variant="contained"
                initial={{
                  x: -300,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
              >
                {'Ver Productos'}
              </Button>
            )}
          </Stack>
          <Stack spacing={1.5}>
            <Grid
              container
              justifyContent={{ xs: 'center', sm: 'space-evenly', md: 'space-between' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={1}>
                <Typography fontWeight={700} color="textSecondary" variant="body2" fontSize={22}>
                  {'Datos de facturación y pago'}
                </Typography>

                {isAuthenticated && (
                  <Button variant="text" onClick={handleChangeFormType}>
                    <Typography
                      fontWeight={600}
                      color="textSecondary"
                      variant="body2"
                      sx={{ textDecoration: 'underline' }}
                      fontSize={14}
                    >
                      {formControl.infoType === formTypeValues.BILLING_AND_PAYMENT
                        ? 'Usar otros datos para facturación'
                        : 'Volver'}
                    </Typography>
                  </Button>
                )}
              </Box>
              {companyInformationEnable.length > 0 && (
                <>
                  <Box>
                    <Button
                      variant="text"
                      endIcon={<Icon sx={{ fontSize: 10 }}>person</Icon>}
                      onClick={(e) => {
                        handleOpenCompanyInformationMenu(e)
                      }}
                    >
                      {'Utilizar datos guardados'}
                    </Button>

                    <>
                      <IconButton
                        id="company-information-btn"
                        aria-controls={
                          openCompanyInformationMenu ? 'company-information-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openCompanyInformationMenu ? 'true' : undefined}
                        onClick={handleOpenCompanyInformationMenu}
                      >
                        <Icon>arrow_drop_down</Icon>
                      </IconButton>
                      <Menu
                        id="company-information-menu"
                        MenuListProps={{
                          'aria-labelledby': 'company-information-btn',
                        }}
                        anchorEl={anchorEl}
                        open={openCompanyInformationMenu}
                        onClose={handleCloseCompanyInformationMenu}
                        TransitionComponent={Fade}
                      >
                        {companyInformationEnable.map((company, index) => {
                          const isSelected =
                            companyInformation.companyInformationId === company.companyInformationId
                          return (
                            <MenuItem
                              sx={{
                                backgroundColor: isSelected ? 'primary.main' : 'transparent',
                                color: isSelected ? '#fff' : '#000',
                                '&:hover': {
                                  backgroundColor: isSelected ? 'primary.main' : 'transparent',
                                  opacity: 0.8,
                                },
                              }}
                              key={company.companyInformationId}
                              onClick={() => {
                                const idType =
                                  company.identificationType === null || !company.identificationType
                                    ? getIdTypeByLength(company.identification.length)
                                    : company.identificationType

                                if (
                                  formTypeValues.BILLING === formControl.tabValue &&
                                  formControl.needBillingInfo
                                ) {
                                  changeInvoiceInformation(company)
                                  setSelectedInvoiceInfoIdType(idType)
                                } else {
                                  setSelectedCompanyInfoIdType(idType)
                                  changeCompanyInformation(company)
                                }
                              }}
                            >
                              {formTypeValues.BILLING === formControl.tabValue &&
                              formControl.needBillingInfo
                                ? company.identification + ' ' + company.name
                                : company.identification +
                                  ' ' +
                                  company.name +
                                  ' ' +
                                  company.lastName}
                            </MenuItem>
                          )
                        })}
                      </Menu>
                    </>
                  </Box>
                </>
              )}
            </Grid>
            {formControl.infoType === formTypeValues.BILLING_AND_PAYMENT && (
              <PaymentForm
                changeCompanyInformation={changeCompanyInformation}
                setFieldErrors={setFieldErrors}
                fieldErrors={fieldErrors}
                companyInformation={companyInformation}
                handleSelectedIdType={handleCompanyInfoIdType}
                selectedIdType={selectedCompanyInfoIdType}
                formControl={formControl}
              />
            )}
            {formControl.infoType === formTypeValues.BILLING_OR_PAYMENT && (
              <TabContext value={formControl.tabValue}>
                <Box>
                  <TabList onChange={handleTabChange}>
                    <Tab label="Facturación" value={formTypeValues.BILLING} />
                    <Tab label="Pago" value={formTypeValues.PAYMENT} />
                  </TabList>
                </Box>
                <TabPanel value={formTypeValues.BILLING}>
                  <BillingForm
                    changeCompanyInformation={changeInvoiceInformation}
                    setFieldErrors={setInvoiceFieldErrors}
                    fieldErrors={invoiceFieldErrors}
                    companyInformation={invoiceInformation}
                    handleSelectedIdType={handleInvoiceInfoIdType}
                    selectedIdType={selectedInvoiceInfoIdType}
                    formControl={formControl}
                  />
                </TabPanel>
                <TabPanel value={formTypeValues.PAYMENT}>
                  <PaymentForm
                    changeCompanyInformation={changeCompanyInformation}
                    setFieldErrors={setFieldErrors}
                    fieldErrors={fieldErrors}
                    companyInformation={companyInformation}
                    handleSelectedIdType={handleCompanyInfoIdType}
                    selectedIdType={selectedCompanyInfoIdType}
                    formControl={formControl}
                  />
                </TabPanel>
              </TabContext>
            )}
          </Stack>
        </Paper>
      </Box>

      <Dialog
        open={openDialog.includes('saveInformation')}
        onClose={() => {
          handleCloseLoad('loadingContinue')
          handleCloseDialog('saveInformation')
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
          {'¿Desea guardar su información?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: 'center', fontWeight: 700, fontSize: 13 }}
          >
            Si deseas guardar la información podrás utilizarla para futuras compras
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: { xs: 2 },
          }}
        >
          <LoadingButton
            sx={{ width: '170px' }}
            disabled={noSavingLoading || savingLoading}
            onClick={() => {
              handleCloseLoad('loadingContinue')
              handleCloseDialog('saveInformation')
            }}
          >
            {'Cancelar'}
          </LoadingButton>
          <LoadingButton
            loading={noSavingLoading}
            disabled={noSavingLoading || savingLoading}
            sx={{ width: '170px' }}
            onClick={handleBtnNotSave}
          >
            {'Continuar sin guardar'}
          </LoadingButton>
          <LoadingButton
            loading={savingLoading}
            disabled={savingLoading || noSavingLoading}
            sx={{ width: '170px' }}
            onClick={handleBtnSaveAndContinue}
            autoFocus
          >
            {'Guardar y continuar'}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <DialogValidateUserId
        onCloseDialog={() => {
          handleCloseDialog('productsNotAvailable')
          handleCloseLoad('loadingContinue')
        }}
        onClickCancel={() => {
          handleCloseDialog('productsNotAvailable')
          handleCloseLoad('loadingContinue')
        }}
        handleChangeIdentificationToRuc={(identification, newRuc, companyName) =>
          handleChangeIdentificationToRuc(identification, newRuc, companyName)
        }
        loadingChangeRuc={load.includes('loadingConfirmProductsNotAvailable')}
      />

      <ModelOtherAccount
        open={openDialog.includes('login')}
        handleOpenDialog={() => {
          handleOpenDialog('selectProcess')
        }}
        loadingInitialize={loadingInitialize}
        initializeConfigureAfterLogin={initializeConfigureAfterLogin}
      />
    </React.Fragment>
  )
}
